import styled from "styled-components";
import {
  color,
  flexbox,
  fontSize,
  fontWeight,
  layout,
  space,
  typography,
  position,
  ColorProps,
  FontSizeProps,
  FontWeightProps,
  LayoutProps,
  SpaceProps,
  FlexboxProps,
  TypographyProps,
  PositionProps,
} from "styled-system";

export type BoxProps =
  | ColorProps
  | FontSizeProps
  | FontWeightProps
  | LayoutProps
  | SpaceProps
  | FlexboxProps
  | TypographyProps
  | PositionProps;

const Box = styled("div")<BoxProps>`
  ${color};
  ${flexbox};
  ${fontSize};
  ${fontWeight};
  ${layout};
  ${typography};
  ${space};
  ${position};
`;

export default Box;
